// stylesheet pertaining specifically to Stripe elements

@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.ErrorMessage {
  color: black;
  position: absolute;
  display: flex;
  justify-content: center;
  padding: 0 15px;
  font-size: 13px;
  margin-top: 0px;
  width: 100%;
  transform: translateY(-15px);
  opacity: 0;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
}

.ErrorMessage svg {
  margin-right: 10px;
}

.Result {
  margin-top: 50px;
  text-align: center;
  animation: fade 200ms ease-out;
}

.ResultTitle {
  color: black;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}

.ResultMessage {
  color: black;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6em;
  text-align: center;
}

.ResetButton {
  border: 0;
  cursor: pointer;
  background: transparent;
}
