@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .btn-primary {
    @apply inline-block text-sm border border-green-500 rounded py-1 px-3 bg-green-500;
    color: #fff !important;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
  }

  .btn-primary-hover {
    @apply bg-green-600;
    cursor: pointer;
  }
}

html,
body,
#__next,
.main {
  height: 100vh;
  padding: 0;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  @apply bg-bg-primary-light dark:bg-bg-primary-dark;
}

* {
  box-sizing: border-box;
}

input,
input[type='text'],
input[type='email'],
input[type='url'],
input[type='password'],
input[type='number'],
input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='week'],
input[multiple],
textarea,
select {
  @apply block box-border w-full rounded-md shadow-sm text-sm border border-solid transition-all;

  @apply border-input-border-light dark:border-input-border-dark focus:outline-none;

  @apply bg-transparent text-form-light dark:text-form-dark;

  @apply focus:border-input-border-focus-light dark:focus:border-input-border-focus-dark;
  @apply focus:ring-input-border-focus-light dark:focus:ring-input-border-focus-dark;

  transition: box-shadow 0.3s ease-in-out;
}

input,
select,
textarea {
  @apply px-4 py-2;
}

input:focus,
input[type='text']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='password']:focus,
input[type='number']:focus,
input[type='date']:focus,
input[type='datetime-local']:focus,
input[type='month']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[multiple]:focus,
textarea:focus,
select:focus,
input:focus {
  box-shadow: 0 0 0 2px rgba(62, 207, 142, 0.1);
}

select option {
  @apply text-bg-primary-dark;
}

input.is-invalid {
  @apply outline-none border-red-500;
}

input[type='submit'] {
  @apply btn-primary;
}

input[type='submit']:hover {
  @apply btn-primary-hover;
}

input::placeholder {
  @apply text-input-placeholder-light dark:text-input-placeholder-dark;
}

.billing-input-fixed {
  @apply shadow appearance-none border rounded w-full py-2 px-3 text-gray-300 leading-tight bg-gray-700;
}

a {
  color: inherit;
  text-decoration: none;
}

.input-mono input {
  @apply font-mono;
}

input[type='radio'] {
  @apply p-0;
}

// TODO: It doesnt' work, need to check
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

code {
  @apply p-1 m-0 font-mono text-sm bg-gray-600 rounded;
}

div[data-radix-portal]:not(.portal--toast) {
  z-index: 2147483646 !important;
}
