.storage-container {
  .sbui-space-col {
    @apply flex-grow;
    #files {
      @apply h-full;
    }
  }
  .sbui-btn {
    @apply focus:outline-none;
  }
  .sbui-btn-default {
    @apply border;
    border-color: transparent !important;
  }
  .sbui-checkbox-container {
    @apply w-auto;
  }
  .sbui-input-container {
    input {
      @apply text-sm;
    }
    .sbui-btn-container {
      .sbui-btn {
        @apply py-1;
      }
    }
  }
  .sbui-menu__item {
    span {
      @apply text-sm;
    }
  }
  .sbui-overlay-container {
    @apply z-10;
  }
}

// May need to just comment this out, the smooth scrolling looks weird
// .file-explorer {
// scroll-behavior: smooth;
// }

.storage-row:hover {
  .storage-row-menu {
    @apply opacity-100;
  }
}

.storage-row-input {
  background-color: #2a2a2a !important;
}

.shimmering-loader {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #fafafa 4%, #ffffff 25%, #fafafa 36%);
  background-size: 1000px 100%;
}

.dark .shimmering-loader {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #181818 4%, #333333 25%, #181818 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.sql-editor-container {
  @apply p-0;
}

.sbui-dropdown__trigger:focus {
  outline: none;
}
