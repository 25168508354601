.editor-product-menu {
  .sbui-typography-text {
    @apply w-full;
  }
  .sbui-menu__item {
    @apply py-0 h-[32px] flex items-center justify-between;
  }
}

.dark .sbui-default-button--dark-white {
  @apply shadow-md text-typography-body-light border-gray-50 bg-white #{!important};
  text-shadow: none !important;
}

.dash-product-menu .sbui-menu__item {
  @apply py-1;
}

/* Brought over from platform to be cleaned if needed */

/* Form panels */

.Form,
.Form button {
  animation: fade 200ms ease-out;
}

.Form .form-control,
.Form select {
  min-width: 250px;
  width: calc(67% - 0.5rem);
  margin-left: 0.5rem;
}

.Form .form-group,
.Form .checkbox {
  @apply w-full;
}

.form-group {
  @apply mb-2 p-0 leading-5 border-0 bg-transparent;
  font-size: 15px;
  display: flex;
  flex-wrap: wrap;
}

label:not(.sbui-formlayout__label):not(.sbui-checkbox__label-container__label):not(.sbui-radio-container):not(.checkbox-pill) {
  @apply inline-block text-input-label-light dark:text-input-label-dark text-sm py-2;
  width: 33%;
  align-self: top;
}

.Form .checkbox .form-check-label {
  @apply flex items-center;
}

.Form .checkbox .form-check-label input[type='checkbox'] {
  @apply mr-4;
  width: auto;
}

.Form input[type='checkbox'] {
  min-width: inherit;
  width: inherit;
}

.Form .form-text,
.Form .form-error {
  @apply block text-sm text-gray-400 my-1;
  display: block;
  margin-left: calc(33% + 0.5rem);
  width: 100%;
}

.Form .form-error {
  @apply text-red-400;
}

.Form .form-help {
  @apply text-gray-400;
}

.Form hr {
  @apply mt-4 mb-4;
}

.section-block {
  @apply p-0 rounded bg-panel-body-light dark:bg-panel-body-dark border border-panel-border-light dark:border-panel-border-dark;
}

.section-block--header {
  @apply border-b border-panel-border-light dark:border-panel-border-dark;
}

.section-block--header h1,
.section-block--header h2,
.section-block--header h3,
.section-block--header h4 {
  @apply m-0;
}

.section-block--header p {
  @apply text-gray-300;
}

.section-block--footer {
  @apply bg-panel-footer-light dark:bg-panel-footer-dark border-t border-panel-border-light dark:border-panel-border-dark;
}

.section-block--footer button:not(.mr-0) {
  @apply mr-2;
}

/*
  Table
*/

.table-container table {
  @apply w-full;
  border-collapse: separate;
  border-spacing: 0;
}

.table-container thead th {
  @apply bg-table-header-light dark:bg-table-header-dark;
  @apply border-t border-b border-table-border-light dark:border-table-border-dark;
}

.table-container thead th:first-child {
  @apply pl-6 rounded rounded-r-none rounded-b-none;
  @apply border-l;
}

.table-container thead th:last-child {
  @apply pr-6 rounded rounded-l-none rounded-b-none;
  @apply border-r;
}

.table-container tbody tr {
  @apply bg-table-body-light dark:bg-table-body-dark;
}

.table-container tbody .tr--link {
  @apply cursor-pointer transition-colors;
}

.table-container tbody .tr--link:hover {
  @apply bg-table-header-light dark:bg-table-header-dark;
}

.table-container tbody td {
  @apply border-b p-3 px-4 text-sm text-gray-300 border-table-border-light dark:border-table-border-dark;
}

.table-container tbody td:first-child {
  @apply pl-6 border-b border-l border-table-border-light dark:border-table-border-dark;
}

.table-container tbody td:last-child {
  @apply pr-6 border-b border-r border-table-border-light dark:border-table-border-dark;
}

.table-container tbody tr:last-child td:first-child {
  @apply rounded rounded-r-none rounded-t-none;
}

.table-container tbody tr:last-child td:last-child {
  @apply rounded rounded-l-none rounded-t-none;
}

.table-container--borderless thead th {
  border-top: none;
}

.table-container--borderless thead th:first-child {
  border-left: none;
}

.table-container--borderless thead th:last-child {
  border-right: none;
}

.table-container--borderless tbody td:first-child {
  border-left: none;
}

.table-container--borderless tbody td:last-child {
  border-right: none;
}

.table-container--borderless tbody tr:last-child td {
  border-bottom: none;
}

/* Docs */
.Docs {
  &--inner-wrapper {
    background: linear-gradient(90deg, #1f1f1f 50%, #2a2a2a 50%);
    width: 100%;
  }

  h1,
  h2,
  h3 {
    @apply p-4 m-0;
  }

  .doc-heading {
    @apply text-white capitalize mt-4 w-1/2;
    hyphens: auto;
    max-width: 50%;
  }

  .doc-section {
    @apply border-b dark:border-dark text-gray-300 flex;
  }

  .text {
    @apply flex-1 p-4 w-1/2;
    max-width: 50%;

    a:not(.btn-primary):not(.btn-secondary) {
      @apply text-green-500;
    }

    a:hover {
      @apply text-green-600;
    }

    p:last-child {
      padding-bottom: 0;
    }
  }

  .code {
    @apply flex-1 p-2 w-1/2 bg-gray-600 pb-4;
    max-width: 50%;

    h4 {
      @apply uppercase font-medium m-1 p-0 text-sm;
    }

    h4:not(:first-child) {
      @apply mt-6;
    }

    code {
      @apply m-1 p-0;
      background-color: none;
    }

    .is-highlighted {
      @apply text-green-500;
    }
  }
}

::-moz-selection {
  /* Code for Firefox */
  @apply bg-green-400;
  color: #333 !important;
}

::selection {
  @apply bg-green-400;
  color: #333 !important;
}

.has-hidden-children {
  .visible-child {
    transition: opacity 0.2s ease;
    opacity: 100;
  }
  .hidden-child {
    transition: opacity 0.2s ease;
    opacity: 0;
  }
}

.has-hidden-children:hover {
  .visible-child {
    opacity: 0;
    pointer-events: none;
  }
  .hidden-child {
    opacity: 100;
  }
}

.SQLTabNav {
  overflow-x: auto;
  overflow-y: hidden !important;

  &::-webkit-scrollbar {
    display: none !important;
  }

  // for Firefox
  scrollbar-width: none; // auto | thin | none | <length>;
}

.SQLTabContainer {
  height: calc(100vh - 3rem);
  min-height: calc(100vh - 3rem);
  .gutter {
    cursor: row-resize;
  }
}
