.table-editor-columns .sbui-formlayout--responsive {
  gap: 0rem !important;
}

.table-editor-columns-input .sbui-input {
  @apply border-none;
}

.table-editor-column-type .sbui-listbox-option-container {
  width: 400px;
}

.table-editor-column-type .sbui-listbox {
  @apply px-2;
}

.column-type-disabled {
  @apply pointer-events-none;
}

.column-type-disabled .sbui-listbox-label {
  @apply opacity-50;
}