.Code {
  width: 100%;

  .prism-code {
    @apply m-0 p-1;
    background-color: none;
    white-space: normal;
    word-break: break-word;

    // overflow: auto !important;
  }

  .token-line {
    @apply text-sm;
  }

  .token.keyword.module,
  .token.function {
    font-style: normal !important;
    color: #ccc !important;
  }

  .token.plain,
  .token.parameter,
  .token.punctuation,
  .token.operator,
  .token.console.class-name,
  .token.doc-comment.parameter,
  .token.doc-comment.optional-parameter,
  .token.doc-comment.parameter.punctuation,
  .token.doc-comment.optional-parameter.punctuation {
    font-style: normal !important;
    color: #ccc !important;
  }

  .token.keyword,
  .token.doc-comment.keyword {
    font-style: normal !important;
    color: #569cd6 !important;
  }

  .token.string {
    font-style: normal !important;
    color: #24b47e !important;
  }

  .token.console {
    font-style: normal !important;
    color: #49c1ad !important;
  }

  .token.constant {
    font-style: normal !important;
    color: #569cd6 !important;
  }

  .token.comment {
    font-style: normal !important;
    color: #666 !important;
  }

  .token.number {
    color: #b5cea8 !important;
  }

  .token.boolean,
  .token.doc-comment.class-name {
    @apply text-green-500;
  }

  .codeBlock {
    @apply bg-gray-600;
    margin-bottom: 0;
    overflow: hidden;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }

  &.codeBlockWrapper {
    position: relative;
  }

  &.codeBlockWrapper:hover .buttonContainer {
    visibility: visible;
    opacity: 1;
  }

  .buttonContainer {
    right: 5px;
    top: 5px;
    opacity: 0;
    position: absolute;
    visibility: hidden;
    transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out, bottom 200ms ease-in-out;

    button {
      @apply rounded text-xs;
      background: rgb(1, 22, 39);
      border: 1px solid rgb(214, 222, 235);
      color: rgb(214, 222, 235);
      cursor: pointer;
      line-height: 12px;
      outline: none;
      padding: 4px 8px;
    }
  }

  &.codeBlockWrapper .sbui-btn-container {
    position: absolute;
    top: 2px;
    right: 3px;

    visibility: hidden;
    opacity: 0;
  }

  &.codeBlockWrapper:hover .sbui-btn-container {
    visibility: visible;
    opacity: 1;
  }
}

.CustomCodeBlock {
  width: 100%;

  .code-with-header pre {
    border-radius: 0 0 4px 4px;
  }

  .code-with-response pre {
    border-radius: 0;
  }

  .code-header {
    @apply bg-gray-600;
    color: #ccc;
    padding: 5px 15px;
    border-radius: 4px 4px 0 0;
    font-size: 0.8rem;
    font-weight: bold;
    border-bottom: 1px solid #444;
  }
}

.FunctionBlock {
  h4 {
    margin-top: 36px;
    margin-bottom: 12px;
  }

  p {
    margin-top: 0px;
    margin-bottom: 24px;
  }
}

.panel-form {
  button {
    @apply m-4 mr-0;
  }

  .form {
    .form-group {
      @apply border-t border-b m-0 -mt-px text-left py-2;

      input {
        @apply px-4 text-white;
        border: none !important;
        width: 100%;
      }
    }
  }
}
