.monaco-editor {
  @apply relative overflow-visible;
  -webkit-text-size-adjust: 100%;
}

.gutter {
  @apply bg-gray-100 dark:bg-gray-600;
  cursor: row-resize;
}

.grid-monaco-editor,
.monaco-editor p,
label,
div,
section,
span.th.tr.td.textarea {
  /* @apply text-sm; */
}

.grid-monaco-editor,
.monaco-editor label {
  font-weight: inherit;
  text-transform: none;
  padding: inherit;
  width: inherit;
  align-self: inherit;
}

.grid-monaco-editor,
.monaco-editor label:hover {
  cursor: inherit;
}

.grid-monaco-editor,
.monaco-editor .suggest-widget .main {
  max-height: none;
  height: inherit;
  width: 100%;
  margin: inherit;
  padding: inherit;
  overflow: inherit;
  background-color: inherit;
}
